// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #b3b3b3;
}

.upload-box {
    background-color: #d9d9d9;
    border-radius: 30px;
    position: relative;
    width: auto;
    font-family: "Inter";
    font-weight: 300;
    padding-left: 100px;
    padding-right: 100px;
}

.upload-box.dragging {
    border: 2px solid #0075FF;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    font-size: 100px;
    font-weight: 150;
    line-height: 48px;
    cursor: pointer;
}

.upload-txt {
    position: relative;
    width: 100%;
    margin: 0;
    font-size: 35px;
    width: 100%;
    padding: 150px 0px;
}

.upload-done-txt {
    position: relative;
    width: 100%;
    margin: 0;
    font-size: 35px;
    width: 100%;
    padding: 25px 0px;
    text-align: center;
}

.upload-box span {
    color: #0075FF;
    text-decoration: none;
    font-size: 35px;
    cursor: pointer;
}

.upload-box span:hover {
    text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/OrderCreation/LCUpload.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,oBAAoB;IACpB,gBAAgB;IAChB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,eAAe;IACf,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,eAAe;IACf,WAAW;IACX,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".upload-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100vh;\n    background-color: #b3b3b3;\n}\n\n.upload-box {\n    background-color: #d9d9d9;\n    border-radius: 30px;\n    position: relative;\n    width: auto;\n    font-family: \"Inter\";\n    font-weight: 300;\n    padding-left: 100px;\n    padding-right: 100px;\n}\n\n.upload-box.dragging {\n    border: 2px solid #0075FF;\n}\n\n.close-button {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    background: none;\n    border: none;\n    font-size: 24px;\n    cursor: pointer;\n    font-size: 100px;\n    font-weight: 150;\n    line-height: 48px;\n    cursor: pointer;\n}\n\n.upload-txt {\n    position: relative;\n    width: 100%;\n    margin: 0;\n    font-size: 35px;\n    width: 100%;\n    padding: 150px 0px;\n}\n\n.upload-done-txt {\n    position: relative;\n    width: 100%;\n    margin: 0;\n    font-size: 35px;\n    width: 100%;\n    padding: 25px 0px;\n    text-align: center;\n}\n\n.upload-box span {\n    color: #0075FF;\n    text-decoration: none;\n    font-size: 35px;\n    cursor: pointer;\n}\n\n.upload-box span:hover {\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
