// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.driver-cont {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
}

.driver-col1 {
    width: 30%;
    background-color: white;
    height: 98%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
}

.driver-col2 {
    width: calc(65%);
    background-color: white;
    height: 98%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Driver/Driver.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,6BAA6B;IAC7B,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,uBAAuB;IACvB,WAAW;IACX,4BAA4B;IAC5B,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,WAAW;IACX,4BAA4B;IAC5B,6BAA6B;IAC7B,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".driver-cont {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: space-evenly;\n    align-items: flex-end;\n}\n\n.driver-col1 {\n    width: 30%;\n    background-color: white;\n    height: 98%;\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n    overflow: hidden;\n}\n\n.driver-col2 {\n    width: calc(65%);\n    background-color: white;\n    height: 98%;\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n    padding-left: 10px;\n    padding-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
