import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './OrderPreview.css';
import axios from 'axios';

const OrderPreview = () => {
    const { orderId } = useParams();
    const navigate = useNavigate();
    const [detCat, setDetCat] = React.useState(0);
    const [newOrder, setNewOrder] = useState({});
    const [currOrderImg, setCurrOrderImg] = useState(null);

    useEffect(() => {
        console.log(newOrder);
    }, [newOrder]);

    useEffect(() => {
        console.log(orderId);
        const fetchOrderDetails = async (orderId) => {
            try {
                const response = await axios.get(process.env.REACT_APP_ORDER_SERVICE_API + '/v1/customers/1234/orders/' + orderId, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.status !== 200) {
                    console.error('Error fetching order details:', response.statusText);
                    return;
                }

                setNewOrder(response.data);

                const preSignedUrl = await axios.post(process.env.REACT_APP_TASK_AUTOMATION_API + '/api/v1/presigned-urls/' + orderId);

                setCurrOrderImg(preSignedUrl.data.presigned_url);
            } catch (error) {
                console.log('Error fetching order details:', error);
            }
        }

        fetchOrderDetails(orderId);
    }, [orderId]);

    const handleDetails = () => {
        if (detCat === 0) {
            return (
                <div className='cat1-cont'>
                    <p>Customer Name</p>
                    <input defaultValue={newOrder?.orderDetails?.customer?.customerName}/>
                    <p>Customer Address</p>
                    <input defaultValue={null} />
                    <p>Load Confirmation</p>
                    <input value={newOrder?.orderDetails?.loadNumber}/>
                    <p>Notification Email</p>
                    <input value={newOrder?.orderDetails?.customer?.notificationEmail}/>
                    <p>Billing Email</p>
                    <input value={newOrder?.orderDetails?.customer?.billingEmail}/>
                    <p>AP Email</p>
                    <input />
                    <p>Phone</p>
                    <input />
                    <p>Other</p>
                    <input />
                </div>
            )
        } else if (detCat === 1) {
            return (
                <div className='cat2-cont'>
                    <div>
                        <p>Rate</p>
                        <input value={newOrder?.orderDetails?.rate?.rate}/>
                        <p>Detention Charges</p>
                        <input />
                        <p>Layover Charges</p>
                        <input />
                        <p>Fuel Surcharge</p>
                        <input />
                        <p>Other Charges</p>
                        <input />
                    </div>
                    <div style={{alignItems: 'flex-end'}}>
                        <p>Currency</p>
                        <input value={newOrder?.orderDetails?.rate?.currencyType}/>
                    </div>
                </div>
            )
        } else if (detCat === 2) {
            return (
                <div className='cat3-cont'>
                    <div className='cat3-row1'>
                        <div className='cat3-row1-col1'>
                            <p>Pickup Date</p>
                            <input value={newOrder?.orderDetails?.shipper[0]?.startDate}/>
                            <p>Pickup Time</p>
                            <input value={newOrder?.orderDetails?.shipper[0]?.startTime}/>
                        </div>
                        <div className='cat3-row1-col2'>
                            <p>Pickup Date</p>
                            <input value={newOrder?.orderDetails?.shipper[0]?.endDate}/>
                            <p>Pickup Time</p>
                            <input value={newOrder?.orderDetails?.shipper[0]?.endTime}/>
                        </div>
                    </div>
                    <div className='cat3-row2'>
                        <p>Pickup Address</p>
                        <input value={newOrder?.orderDetails?.shipper[0]?.shipperAddress}/>
                        <p>Shipper Notes</p>
                        <input />
                    </div>
                    <div className='cat3-row3'>
                        <div className='cat3-row3-col1'>
                            <p>FTL or LTL</p>
                            <input />
                            <p>Temp</p>
                            <input />
                            <p>Pickup #</p>
                            <input />
                        </div>
                        <div className='cat3-row3-col2'>
                            <p>Load Count</p>
                            <input />
                            <p>Hazmat</p>
                            <input />
                            <p>Weight</p>
                            <input />
                        </div>
                    </div>
                    <div className='cat3-row4'>
                        <p>Shipment Notes</p>
                        <input />
                        <p>Other Notes</p>
                        <input />
                    </div>
                </div>
            )
        } else if (detCat === 3) {
            return (
                <div className='cat4-cont'>
                    <div className='cat4-row1'>
                        <div className='cat4-row1-col1'>
                            <p>Delivery Date</p>
                            <input value={newOrder?.orderDetails?.consignee[0]?.startDate}/>
                            <p>Delivery Time</p>
                            <input value={newOrder?.orderDetails?.consignee[0]?.startTime}/>
                        </div>
                        <div className='cat4-row1-col2'>
                            <p>Delivery Date</p>
                            <input value={newOrder?.orderDetails?.consignee[0]?.endDate}/>
                            <p>Delivery Time</p>
                            <input value={newOrder?.orderDetails?.consignee[0]?.endTime}/>
                        </div>
                    </div>
                    <div className='cat4-row2'>
                        <p>Delivery Address</p>
                        <input value={newOrder?.orderDetails?.consignee[0]?.consigneeAddress}/>
                        <p>Delivery Notes</p>
                        <input />
                        <p>Delivery Notes</p>
                        <input />
                        <p>Other Notes</p>
                        <input />
                    </div>
                </div>
            )
        } else if (detCat === 4) {
            return (
                <div className='cat5-cont'>
                    <p>Notes</p>
                    <input />
                </div>
            )
        }
    }

    useEffect(() => {
        console.log(detCat)
    }, [detCat])

    return (
        <div className="order-preview-container">
            <div className='order-img-cont'>
                <img src={currOrderImg} alt="order-preview" className="order-img" />
            </div>
            <div className='order-det-cont'>
                <div className='order-cats'>
                    <h2 className={detCat === 0 ? 'cat active' : 'cat'} onClick={() => setDetCat(0)}>Customer</h2>
                    <h2 className={detCat === 1 ? 'cat active' : 'cat'} onClick={() => setDetCat(1)}>Rate</h2>
                    <h2 className={detCat === 2 ? 'cat active' : 'cat'} onClick={() => setDetCat(2)}>Pickup</h2>
                    <h2 className={detCat === 3 ? 'cat active' : 'cat'} onClick={() => setDetCat(3)}>Delivery</h2>
                    <h2 className={detCat === 4 ? 'cat active' : 'cat'} onClick={() => setDetCat(4)}>Notes</h2>
                </div>
                <div className='order-det'>
                    {handleDetails()}
                </div>
                <div className='order-actns'>
                    <button className='cancel-actn' onClick={() => navigate('/dashboard')}>CANCEL</button>
                    <button className='accept-actn' onClick={() => navigate('/dashboard')}>ACCEPT</button>
                </div>
            </div>
        </div>
    );
};

export default OrderPreview;

{/* <button onClick={() => navigate('/dashboard')}>&larr; Back</button> */ }

