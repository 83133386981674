import { useState } from 'react';
import "./Dashboard.css";
import plus from "../../assets/images/plus.png";
import { useNavigate } from 'react-router-dom';
import Order from './Order/Order.js';
import IntergrationsPreview from './Intergrations/IntergrationsPreview.js';
import Driver from './Driver/Driver.js';

function Dashboard({ newOrders }) {
    const navigate = useNavigate();
    const [navItem, setNavItem] = useState(0);

    const handleSection = () => {
        if (navItem === 0) {

        } else if (navItem === 1) {
            return (
                <Order newOrders={newOrders} />
            )
        } else if (navItem === 2) {

        } else if (navItem === 3) {

        } else if (navItem === 4) {
        
        } else if (navItem === 5) {

        } else if (navItem === 6) {
            return (
                <Driver />
            )
        } else if (navItem === 7) {
            return (
                <IntergrationsPreview />
            )
        } else if (navItem === 8) {
        
        }
    }

    return (
        <div className='dashboard-pg'>

            <div className='dashboard-header'>
                <h3>Important Notification in Order</h3>
                <p>User's Logo</p>
            </div>

            <div className='dashboard-cont'>

                <div className='dashboard-col1'>

                    <div className='col1-nav'>
                        <p onClick={() => setNavItem(0)} className={navItem === 0 ? 'col1-nav-item active' : 'col1-nav-item'}>Home</p>
                        <p onClick={() => setNavItem(1)} className={navItem === 1 ? 'col1-nav-item active' : 'col1-nav-item'}>Order</p>
                        <p onClick={() => setNavItem(2)} className={navItem === 2 ? 'col1-nav-item active' : 'col1-nav-item'}>Trip</p>
                        <p onClick={() => setNavItem(3)} className={navItem === 3 ? 'col1-nav-item active' : 'col1-nav-item'}>Invoice</p>
                        <p onClick={() => setNavItem(4)} className={navItem === 4 ? 'col1-nav-item active' : 'col1-nav-item'}>Address</p>
                        <p onClick={() => setNavItem(5)} className={navItem === 5 ? 'col1-nav-item active' : 'col1-nav-item'}>Equipment</p>
                        <p onClick={() => setNavItem(6)} className={navItem === 6 ? 'col1-nav-item active' : 'col1-nav-item'}>Driver</p>
                        <p onClick={() => setNavItem(7)} className={navItem === 7 ? 'col1-nav-item active' : 'col1-nav-item'}>Integrations</p>
                        <p onClick={() => setNavItem(8)} className={navItem === 8 ? 'col1-nav-item active' : 'col1-nav-item'}>Settings</p>

                    </div>

                    <div className='col2-actns'>

                        <div className='col2-actn' onClick={() => navigate('/upload-load-confirmation')}>
                            <img src={plus} />
                            <p>Create Order</p>
                        </div>

                        <div className='col2-actn'>
                            <img src={plus} />
                            <p>Create Trip</p>
                        </div>

                    </div>

                </div>

                <div className='dashboard-col2'>
                    {handleSection()}
                </div>

            </div>

        </div>
    );
}

export default Dashboard;