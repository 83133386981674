import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard.js';
import LCUpload from './components/OrderCreation/LCUpload.js';
import OrderPreview from './components/OrderCreation/OrderPreview.js';
import Auth from './components/Auth/Auth.js';
import { useState } from 'react';
import Signup from './components/Auth/Signup.js';
import IntergrationsPreview from './components/Dashboard/Intergrations/IntergrationsPreview.js'

function App() {
  const [auth, setAuth] = useState(false);
  const [newOrders, setNewOrders] = useState([]);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/login" element={<Auth />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/dashboard" element={<Dashboard newOrders={newOrders} />} />
          <Route exact path="/upload-load-confirmation" element={<LCUpload setNewOrders={setNewOrders} />} />
          <Route exact path="/order-preview/:orderId" element={<OrderPreview />} />
          <Route exact path="/intergrations-preview" element={<IntergrationsPreview />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
