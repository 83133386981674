import { useState } from 'react';
import './LCUpload.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const LCUpload = ({ setNewOrders }) => {
    const navigate = useNavigate();
    const [dragging, setDragging] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = 'copy';
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        const files = e.dataTransfer.files;
        handleFiles(files);
    };

    const handleFiles = async (files) => {
        console.log(files[0]);
        const formData = new FormData();
        formData.append('file', files[0]);

        if (files[0].type !== 'application/pdf') {
            alert('Invalid file type. Please upload a PDF file.');
            return;
        }

        try {
            setIsUploaded(true);
            const response = await axios.post(process.env.REACT_APP_TASK_AUTOMATION_API + '/api/v1/docs/load-confirmation/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status !== 200) {
                console.error('Error uploading file:', response.statusText);
                return;
            }

            console.log(response.status)
            console.log('File uploaded successfully:', response.data);
            setNewOrders(prevOrders => [...prevOrders, response.data.orderId]);
        } catch (error) {
            console.log('Error uploading file:', error);
        }
    };

    const handleBrowseFiles = (e) => {
        e.preventDefault();
        document.getElementById('fileInput').click();
    };

    const handleFileChange = (e) => {
        const files = e.target.files;
        handleFiles(files);
    };

    return (
        <div className="upload-container">
            {isUploaded ?
                <div className="upload-box">
                    <p className='upload-done-txt'>File uploaded successfully!</p>
                    <p className='upload-done-txt'>Return to <span onClick={() => navigate('/dashboard')} style={{ textDecoration: 'underline', color: 'blue' }}>Dashboard</span></p>
                </div>
                :
                <div className={`upload-box ${dragging ? 'dragging' : ''}`} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDragOver={handleDragOver} onDrop={handleDrop}>
                    <p onClick={() => navigate('/')} className="close-button">&times;</p>
                    <p className='upload-txt'>Drop files here or <span onClick={handleBrowseFiles}>browse files</span></p>
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        id="fileInput"
                        className="hidden"
                        onChange={handleFileChange}
                        multiple
                    />
                </div>
            }
        </div>
    );
};

export default LCUpload;
