import React from 'react';
import { useState } from 'react';
import greyCircle from "../../../assets/images/circle_grey.png";
import redCircle from "../../../assets/images/circle_red.png";
import dottedLine from "../../../assets/images/dotted_line.png";
import map from "../../../assets/images/map.png";
import { useNavigate } from 'react-router-dom';
import './Order.css';

function Order({ newOrders }) {
    const [category, setCategory] = useState(0);
    const navigate = useNavigate();

    const sampleItems = [
        {
            id: 123457,
            customer: 'Apotex',
            loadNum: 'BCW3254325',
            pickupTime: "06.05.2024 9:00AM",
            pickupLocation: 'WALMART',
            dropoffTime: "06.07.2024 10:00AM",
            dropoffLocation: 'SOBEYS',
        }
        ,
        {
            id: 123458,
            customer: 'Apotex',
            loadNum: 'BCW3254326',
            pickupTime: "06.05.2024 10:00AM",
            pickupLocation: 'WALMART',
            dropoffTime: "06.07.2024 11:00AM",
            dropoffLocation: 'SOBEYS',
        },
        {
            id: 3,
            customer: 'Apotex',
            loadNum: 'BCW3254327',
            pickupTime: "06.05.2024 11:00AM",
            pickupLocation: 'WALMART',
            dropoffTime: "06.07.2024 12:00AM",
            dropoffLocation: 'SOBEYS',
        },
        {
            id: 4,
            customer: 'Apotex',
            loadNum: 'BCW3254328',
            pickupTime: "06.05.2024 12:00AM",
            pickupLocation: 'WALMART',
            dropoffTime: "06.07.2024 1:00AM",
            dropoffLocation: 'SOBEYS',
        },
        {
            id: 5,
            customer: 'Apotex',
            loadNum: 'BCW3254329',
            pickupTime: "06.05.2024 1:00AM",
            pickupLocation: 'WALMART',
            dropoffTime: "06.07.2024 2:00AM",
            dropoffLocation: 'SOBEYS',
        },
        {
            id: 6,
            customer: 'Apotex',
            loadNum: 'BCW3254330',
            pickupTime: "06.05.2024 2:00AM",
            pickupLocation: 'WALMART',
            dropoffTime: "06.07.2024 3:00AM",
            dropoffLocation: 'SOBEYS',
        }
    ];

    const sortOrders = () => {
        if (category === 0) {
            return newOrders?.map((order, index) => (
                <div key={index} onClick={() => navigate('/order-preview/' + order)} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} className='col2-item'>
                    <h2>New Order: {order}</h2>
                    <p>Click to confirm details</p>
                </div>
            ))
        } else if (category === 1) {
            return sampleItems.map((item) => (
                <div className='col2-item'>

                    <div className='col2-item-details'>

                        <div className='col2-item-det1'>
                            <p>Order ID# <span style={{ color: '#727070', fontWeight: 'bold' }}>{item.loadNum}</span><br />Customer <span style={{ color: '#727070', fontWeight: 'bold' }}>{item.customer}</span></p>
                        </div>

                        <div className='col2-item-det2'>
                            <p>Load# <span style={{ color: '#727070', fontWeight: 'bold' }}>{item.loadNum}</span><br /></p>
                        </div>

                    </div>

                    <div className='col2-item-imgs-cont'>

                        <div className='col2-item-imgs'>
                            <img className='col2-circle-red' src={greyCircle} />
                            <img className='col2-line' src={dottedLine} />
                            <img className='col2-circle-grey' src={redCircle} />
                        </div>

                        <div className='col2-item-det3'>
                            <p><span style={{ color: '#727070', fontWeight: 'bold' }}>Pickup</span>: {item.pickupTime}<br />{item.pickupLocation}</p>
                            <p><span style={{ color: '#727070', fontWeight: 'bold' }}>Delivery</span>: {item.dropoffTime}<br />{item.dropoffLocation}</p>
                        </div>

                    </div>

                </div>
            ))
        } else if (category === 2) {
            return sampleItems.map((item) => (
                <div className='col2-item'>

                    <div className='col2-item-details'>

                        <div className='col2-item-det1'>
                            <p>Order ID# <span style={{ color: '#727070', fontWeight: 'bold' }}>{item.loadNum}</span><br />Customer <span style={{ color: '#727070', fontWeight: 'bold' }}>{item.customer}</span></p>
                        </div>

                        <div className='col2-item-det2'>
                            <p>Load# <span style={{ color: '#727070', fontWeight: 'bold' }}>{item.loadNum}</span><br /></p>
                        </div>

                    </div>

                    <div className='col2-item-imgs-cont'>

                        <div className='col2-item-imgs'>
                            <img className='col2-circle-red' src={greyCircle} />
                            <img className='col2-line' src={dottedLine} />
                            <img className='col2-circle-grey' src={redCircle} />
                        </div>

                        <div className='col2-item-det3'>
                            <p><span style={{ color: '#727070', fontWeight: 'bold' }}>Pickup</span>: {item.pickupTime}<br />{item.pickupLocation}</p>
                            <p><span style={{ color: '#727070', fontWeight: 'bold' }}>Delivery</span>: {item.dropoffTime}<br />{item.dropoffLocation}</p>
                        </div>

                    </div>

                </div>
            ))
        }
    }

    return (
        <div className='order-cont'>

            <div className='order-col2'>

                <div className='col2-search'>
                    <input placeholder='Search' />
                </div>

                <div className='col2-opts'>
                    <p onClick={() => setCategory(0)} className={category === 0 ? 'col2-opts-cat active' : 'col2-opts-cat'}>New Order</p>
                    <p onClick={() => setCategory(1)} className={category === 1 ? 'col2-opts-cat active' : 'col2-opts-cat'}>Active Order</p>
                    <p onClick={() => setCategory(2)} className={category === 2 ? 'col2-opts-cat active' : 'col2-opts-cat'}>Archive Order</p>
                </div>

                <div className='col2-items'>
                    {sortOrders()}
                </div>

            </div>

            <div className='order-col3'>

                <div className='col3-row1'>

                    <div className='col3-row1-info1'>
                        <p>
                            <span style={{ fontWeight: 'bold' }}>Apotex</span>
                            <br />
                            1234 Satellite Drive
                            <br />
                            Mississauga, Ontario, Canada
                            <br />
                            Order ID#  <span style={{ fontWeight: 'bold' }}>123457</span>
                            <br />
                            Load# <span style={{ fontWeight: 'bold' }}>BCW3254325</span>
                        </p>
                    </div>

                    <div className='col3-row1-info2'>
                        <p>
                            Notification Email: <span style={{ fontWeight: 'bold' }}>xxx.abc@gmail.com</span>
                            <br />
                            Billing Email: <span style={{ fontWeight: 'bold' }}>accounting@gmail.com</span>
                            <br />
                            AP Email: <span style={{ fontWeight: 'bold' }}>AP@gmail.com</span>
                        </p>
                    </div>

                </div>

                <div className='col3-row2'>

                    <div className='col3-row2-info1'>
                        <p>
                            <span style={{ fontWeight: 'bold' }}>Pickup</span>
                            <br />
                            Date: 06.05.2024
                            <br />
                            Time: 9:00AM to 10:00AM
                            <br />
                            <br />
                            WALMART
                            <br />
                            1234 Dubonet Road
                            <br />
                            Mississauga, Ontario, Canada
                        </p>
                    </div>

                    <div className='col3-row2-info2'>
                        <p>
                            <span style={{ fontWeight: 'bold' }}>Pickup Details:</span>
                            <br />
                            Full or LTL
                            <br />
                            Pickup# 123456
                            <br />
                            Reefer:
                            <br />
                            Temp:
                            <br />
                            Hazmat:
                            <br />
                            Reference# 123456
                            <br />
                            Commodity: Rice, Load
                            <br />
                            Weight: 44,0000
                            <br />
                            Instruction:
                        </p>
                    </div>

                </div>

                <div className='col3-row3'>

                    <div className='col3-row3-info1'>
                        <p>
                            <span style={{ fontWeight: 'bold' }}>Delivery</span>
                            <br />
                            Date: 06.07.2024
                            <br />
                            Time: 10:00AM
                            <br />
                            <br />
                            SOBEYS
                            <br />
                            987 Tace Drive
                            <br />
                            Mississauga, Ontario, Canada
                        </p>
                    </div>

                    <div className='col3-row3-info2'>
                        <p>
                            <span style={{ fontWeight: 'bold' }}>Delivery Details:</span>
                            <br />
                            Go to door 14
                        </p>
                    </div>

                </div>

                <div className='col3-map'>
                    <img className='map-img' src={map} />
                </div>

            </div>
        </div>
    );
}

export default Order;