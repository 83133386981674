import React, { useState, useEffect } from 'react';
import './IntergrationsPreview.css';
import quickBooksImage from "../../../assets/images/quickBooks.png";
import motiveImage from "../../../assets/images/motive.png";
import axios from 'axios';

const ButtonToggle = ({ buttonOn }) => {

    const [isEnabled, setIsEnabled] = useState(false);

    useEffect(() => {
        const storedButtonState = sessionStorage.getItem(buttonOn);
        if (storedButtonState !== null) {
            setIsEnabled(JSON.parse(storedButtonState));
        }
    }, []);

    const handleEnable = async () => {

        const authCode = '';
        const state = '';
        const realmId = '';
        let url;

        if (buttonOn === 'quickbooks') {
            url = '/oauth2redirect?code=${authCode}&state=${state}&realmId=${realmId}'
        }
        if (!isEnabled) {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + url, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.status) {
                    setIsEnabled(true);
                    sessionStorage.setItem(buttonOn, JSON.stringify(true));

                    console.log(buttonOn + ' enabled successfully:', response.data);
                } else {
                    console.error('Error:', response.statusText);
                }
            } catch (error) {
                console.log('Error enabling ' + buttonOn, error);
            }
        }

    }

    return (
        <button className={`button2-toggle ${isEnabled ? 'enabled' : ''}`} onClick={handleEnable}>
            {isEnabled ? 'Enabled' : 'Enable App'}
        </button>
    );
};

const handleClick = async (buttonText) => {

    let url;
    if (buttonText === 'Financial') {
        url = '/connect';
    }
    try {
        const response = await axios.get(process.env.REACT_APP_API_URL + url, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (response.status) {
            const data = await response.json();
            const redirectUrl = 'http://127.0.0.1:8081';
            window.location.href = redirectUrl;
        } else {
            console.error('Error:', response.statusText);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

const IntergrationCard = ({ image, altText, content, buttonText }) => {

    return (
        <div className='intergration-card'>
            <header>
                <img src={image} alt={altText} className='intergration-img' />
            </header>
            <div>{content}</div>
            <footer className='intergration-actns'>
                <button className='button1-actn' onClick={() => handleClick(buttonText)}>{buttonText}</button>
                <ButtonToggle buttonOn={altText} />
            </footer>
        </div>
    );
};

const IntergrationsPreview = () => {
    return (
        <div className='intergrations-body'>
            <div className='intergrations-header'>
                <h1>INTERGRATIONS</h1>
            </div>
            <div className="intergrations-container">
                <IntergrationCard
                    image={quickBooksImage}
                    altText='quickbooks'
                    content={
                        <ol>
                            <li>Invoices created in Transpots automatically export to QuickBooks.</li>
                            <li>Any changes made are updated in both systems.</li>
                        </ol>
                    }
                    buttonText='Financial'
                    navigatePath='/dashboard'
                />
                <IntergrationCard
                    image={motiveImage}
                    altText='motive'
                    content={<p>Enable real-time track and trace for your trucks & track driver HOS using Motive's GPS data.</p>}
                    buttonText='Track & Trace'
                    navigatePath='/dashboard'
                />
            </div>
        </div>
    );
};

export default IntergrationsPreview;
