import React from 'react';
import './Signup.css';
import logo from '../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';

function Signup() {
    const navigate = useNavigate();
    const email = 'shri@transpots.ca'
    const password = 'pass'

    const handleLogin = (e) => {
        e.preventDefault();
        const emailInput = e.target[0].value;
        const passwordInput = e.target[1].value;

        if (emailInput === email && passwordInput === password) {
            navigate('/dashboard');
        } else {
            alert('Invalid email or password');
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <h1>Welcome</h1>
                <img src={logo} alt="Transpots Logo" className="logo" />
                <form onSubmit={handleLogin}>
                    <input type="email" placeholder="Email" className="input-field" />
                    <input type="password" placeholder="Password" className="input-field" />
                    <button type="submit" className="login-button">LOGIN</button>
                </form>
                <div className='form-footer'>
                    <a href='/login'>Sign In</a>
                </div>
            </div>
        </div>
    );
}

export default Signup;
