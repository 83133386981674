import React, { useState } from 'react';
import './Driver.css';

function Driver() {
    const [category, setCategory] = useState(0);
    const [driver, setDrivers] = useState([
        {
            name: 'John Doe',
            phone: '123-456-7890',
            email: 'johndoe@gmail.com',

        }
    ]);

    return (
        <div className='driver-cont'>

            <div className='driver-col1'>

                <div className='col2-search'>
                    <input placeholder='Search' />
                </div>

                <div className='col2-opts'>
                    <p onClick={() => setCategory(0)} className={category === 0 ? 'col2-opts-cat active' : 'col2-opts-cat'}>Active</p>
                    <p onClick={() => setCategory(1)} className={category === 1 ? 'col2-opts-cat active' : 'col2-opts-cat'}>Not Active</p>
                    <p onClick={() => setCategory(2)} className={category === 2 ? 'col2-opts-cat active' : 'col2-opts-cat'}>ADD NEW</p>
                </div>

                <div className='col2-items driver'>

                </div>

            </div>

            <div className='driver-col2'>
                <div className='driver-name-header'>

                </div>
                <div className='driver-contact-info'>

                </div>
                <div className='driver-details'>
                    <p>Driver License</p>
                    
                </div>
            </div>
        </div>
    );
}

export default Driver;